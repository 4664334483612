<template>
  <v-container fluid class="emp-assignments-view">
    <PageTitle
      title="Employee Assignments"
      :show_help_icon="false"
      counter_count="100"
    />
    <RemovableBanner
      first_text_line="We’ve just announced our Series A!"
      second_text_line="Read about it from our CEO."
      :lines="1"
      :removed="false"
      banner_type="white"
    />
    <div class="list-section">
      <NoRecordFound
        v-if="dataList.length === 0"
        title="Employee Assignments"
        message="Assignments"
        :subtitle="''"
        class="no-record-found"
        :shouldShowAddAction="false"
        :noBorder="true"
      />
      <EmployeeAssignments v-else :dataSource="dataList" />
    </div>
  </v-container>
</template>

<script>
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
import EmployeeAssignments from "@components/EmployeeAssignments/EmployeeAssignments.vue";
import RemovableBanner from "@components/RemovableBanner/RemovableBannerComponent.vue";
import PageTitle from "@components/common/PageTitle.vue";

export default {
  name: "EmployeeAssignmentView",
  components: {
    NoRecordFound,
    EmployeeAssignments,
    PageTitle,
    RemovableBanner,
  },
  data() {
    return {
      showNotification: true,
      dataList: [],
    };
  },
  created() {
    this.$store.dispatch("positionStore/loadPositions");
    this.$store.dispatch("readinessCatalogsStore/loadRoles");
    this.$store.dispatch("readinessCatalogsStore/loadResponsibilities");
  },
  mounted() {
    const userId = +this.$route.params.id;
    const positions = [...this.storePositions];
    const activeAssignments = positions.filter(
      (position) =>
        position.status === "active" &&
        position.assigned_employees?.some((employee) => employee.id === userId)
    ).length;

    this.$store.state.positionStore.activeAssignmentsCount = activeAssignments;
  },
  computed: {
    storePositions() {
      return this.$store.getters["positionStore/positions"] || [];
    },
    storeRoles() {
      return this.$store.getters["readinessCatalogsStore/allRoles"] || [];
    },
    storeResponsibilities() {
      return (
        this.$store.getters["readinessCatalogsStore/allResponsibilities"] || []
      );
    },
  },
  watch: {
    storePositions() {
      this.tryBuildAssignments();
    },
    storeRoles() {
      this.tryBuildAssignments();
    },
    storeResponsibilities() {
      this.tryBuildAssignments();
    },
  },
  methods: {
    hideNotification() {
      this.showNotification = false;
    },
    tryBuildAssignments() {
      if (
        this.storePositions.length > 0 &&
        this.storeRoles.length > 0 &&
        this.storeResponsibilities.length > 0
      ) {
        const userId = +this.$route.params.id;
        this.dataList = this.buildEmployeeAssignments(
          userId,
          this.storePositions,
          this.storeRoles,
          this.storeResponsibilities
        );
        console.log("DataList:", this.dataList);
      }
    },

    buildEmployeeAssignments(userId, positions, roles, responsibilities) {
      const userPositions = positions.filter((pos) =>
        pos.assigned_employees?.some((emp) => emp.id === userId)
      );
      const result = [];
      let nodeIdCounter = 1;
      userPositions.forEach((position) => {
        const positionId = nodeIdCounter++;
        result.push({
          id: positionId,
          parentId: -1,
          data: {
            type: "position",
            commitmentLevel: "Acting as",
            assignmentType: "Position",
            importanceLevel: 2,
            importanceSelector: true,
            totalSteps: 3,
            code: this.extractCode(position?.code_str),
            name: position.name,
            isNew: false,
            isAccepted: false,
          },
          status: position.status,
          assignedBy: {
            name: "Olivia Rhye",
            picture: "",
            userType: "employee",
            displayDate: "15-Nov-24",
          },
          removedBy: {
            name: "Olivia Rhye",
            picture: "",
            userType: "employee",
            displayDate: "15-Nov-24",
          },
        });

        const attachedRoles = roles.filter((role) =>
          role.actively_attached_to_positions?.some(
            (rp) => rp.id === position.id
          )
        );

        attachedRoles.forEach((role) => {
          const roleId = nodeIdCounter++;
          result.push({
            id: roleId,
            parentId: positionId,
            data: {
              type: "role",
              commitmentLevel: "Primary",
              assignmentType: "Role",
              importanceLevel: 3,
              importanceSelector: true,
              totalSteps: 3,
              code: this.extractCode(role?.code_str),
              name: role.name,
              isNew: true,
              isAccepted: true,
            },
            status: role.status,
            assignedBy: {
              name: "Olivia Rhye",
              picture: "",
              userType: "employee",
              displayDate: "15-Nov-24",
            },
            removedBy: {
              name: "Olivia Rhye",
              picture: "",
              userType: "employee",
              displayDate: "15-Nov-24",
            },
          });

          const attachedResponsibilities = responsibilities.filter((resp) =>
            resp.attachments?.some((a) => a.type === "role" && a.id === role.id)
          );

          attachedResponsibilities.forEach((resp) => {
            const respId = nodeIdCounter++;
            result.push({
              id: respId,
              parentId: roleId,
              data: {
                type: "responsibility",
                commitmentLevel: "Primary",
                assignmentType: "Responsibility",
                importanceLevel: 1,
                importanceSelector: true,
                totalSteps: 3,
                code: this.extractCode(resp?.code_str),
                name: resp.name,
                isNew: true,
                isAccepted: true,
              },
              status: resp.status,
              assignedBy: {
                name: "Olivia Rhye",
                picture: "",
                userType: "employee",
                displayDate: "15-Nov-24",
              },
              removedBy: {
                name: "Olivia Rhye",
                picture: "",
                userType: "employee",
                displayDate: "15-Nov-24",
              },
            });
          });
        });
      });
      return result;
    },
    extractCode(str) {
      let match = str?.match(/#\d+/);
      return match ? match[0] : null;
    },
  },
};
</script>

<style>
.emp-assignments-view .main-heading {
  font-family: Inter;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  text-align: left;
  color: #101828;
  padding-bottom: 20px;
}
.emp-assignments-view .notification-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #eaecf0;
  border-radius: 10px;
  margin-bottom: 25px;
}
.emp-assignments-view .list-section {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-bottom: 24px;
}
.emp-assignments-view .notifi-left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.emp-assignments-view .notification-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  color: #344054;
  text-align: left;
}
.emp-assignments-view .notification-description {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #475467;
  text-align: left;
}
</style>
