import { defineStore } from "pinia";
import { getListOfPositions } from "@/api";
import {
  getPositions,
  updatePositions,
} from "@components/helper/localStorageHelper";

import { useReadinessCatalogsStore } from "@/store/pinia/readinessCatalog";

export const usePositionStore = defineStore("positions", {
  state: () => ({
    positions: [],
    activePositionsCount: 0,
    totalPositionsCount: 0,
  }),

  getters: {
    getPositions: (state) => state.positions,
    getActivePositionsCount: (state) => state.activePositionsCount,
    getTotalPositionsCount: (state) => state.totalPositionsCount,

    getAttachedRolesFromPositionId: () => {
      // En Pinia podemos acceder a otros stores directamente
      const readinessCatalogsStore = useReadinessCatalogsStore();

      return (positionId) => {
        if (!positionId) return [];
        return readinessCatalogsStore.roles
          .filter((role) => {
            return role.attachments.some(
              (attachment) =>
                attachment.type === "position" && attachment.id === positionId
            );
          })
          .map((role) => ({
            ...role,
            parentId: -1,
          }));
      };
    },

    getAttachedRolesAndResponsibilitiesFromPositionId: () => {
      const readinessCatalogsStore = useReadinessCatalogsStore();

      return (positionId) => {
        if (!positionId) return [];

        const rolesForPosition = readinessCatalogsStore.roles
          .filter((role) => {
            return role.attachments.some(
              (attachment) =>
                attachment.type === "position" && attachment.id === positionId
            );
          })
          .map((role) => ({
            ...role,
            type: "role",
            parentId: -1,
          }));

        const responsibilitiesForPosition =
          readinessCatalogsStore.responsibilities
            .filter((responsibility) => {
              return responsibility.attached_roles.some((attachment) =>
                rolesForPosition.some((role) => role.id === attachment.id)
              );
            })
            .map((responsibility) => {
              const attachedRole = responsibility.attached_roles.find(
                (attachment) =>
                  rolesForPosition.some((role) => role.id === attachment.id)
              );
              return {
                ...responsibility,
                type: "responsibility",
                parentId: attachedRole ? attachedRole.id : -1,
              };
            });

        return [...rolesForPosition, ...responsibilitiesForPosition];
      };
    },
  },

  actions: {
    setPositions(positions) {
      this.positions = positions;
      const activePositions = positions.filter(
        (el) => el.status == "active"
      ).length;
      this.activePositionsCount = activePositions;
    },

    setActivePositionsCount(count) {
      this.activePositionsCount = count;
    },

    setTotalPositionsCount(count) {
      this.totalPositionsCount = count;
    },

    updateActivePositionsCount(count) {
      this.setActivePositionsCount(count);
    },

    async loadPositions() {
      try {
        let positionsList = getPositions();
        this.setPositions(positionsList);

        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        const response = await getListOfPositions(accountId, 1, 500);

        if (response.data?.count) {
          this.totalPositionsCount = response.data.count;
        }

        if (response.data?.results) {
          updatePositions(response.data.results);
          this.setPositions(response.data.results);
        }
      } catch (e) {
        console.error("error fetching positions: ", e);
      }
    },
  },
});
