import { reactive, computed, getCurrentInstance } from "vue";
import { useEmployees } from "@/composables/employees/useEmployees";
import { useFilters } from "@/composables/filters/useFilters";

let instance = null;

export function useEmployeeViewState() {
  if (instance) return instance;

  const employees = useEmployees();
  const filter = useFilters();
  const app = getCurrentInstance();
  const { proxy } = app;

  const state = reactive({
    filters: {
      values: [],
      searchText: "",
      showFilterDialog: false,
      key: "organizationEmployees",
      clearSelection: "",
      statusOptions: ["draft", "active", "terminated"],
      filterGroups: [
        {
          label: "Vacancy",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Vacant", value: "vacant" },
          ],
        },
        {
          label: "Availability",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Availability 0.5 FTE", value: "avail_05_fte" },
            { label: "Availability 1 FTE", value: "avail_1_fte" },
          ],
        },
        {
          label: "Access",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "No Access", value: "no_access" },
            { label: "Invited", value: "invited" },
            { label: "Has Access", value: "has_access" },
            { label: "Suspended", value: "suspended" },
            { label: "Blocked", value: "blocked" },
          ],
        },
      ],
    },
    loading: {
      table: false,
      assignmentWizard: false,
      relieveAssignment: false,
    },
    assignmentWizard: {
      show: false,
      selectedPosition: null,
    },
    relieveAssignment: {
      show: false,
      currentPosition: "",
      confirmationPending: false,
    },
    activeEmployeeModal: {
      show: false,
      message: "",
    },
    terminateEmployeeModal: {
      show: false,
      message: "",
    },
    addEmployeeModal: {
      show: false,
      message: "",
    },
    changePictureModal: {
      show: false,
      avatarValue: null,
      croppedImage: null,
    },
    giveAccessModal: {
      show: false,
      dataType: "step1",
      existingUser: null,
    },
    reactivateEmployeeModal: {
      show: false,
      message: "",
    },
    deleteEmployeeModal: {
      show: false,
      message: "",
    },
    table: {
      selectedEmployees: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      search: "",
    },
  });
  const hasSelectedEmployees = computed(
    () => state.table.selectedEmployees.length > 0
  );

  const filteredEmployees = computed(() => {
    let allEmployees = employees.allEmployees.value;

    if (state.filters.searchText && state.filters.searchText.length > 0) {
      const searchText = state.filters.searchText.toLowerCase();

      allEmployees = allEmployees.filter((el) => {
        const fullName = `${el.first_name} ${el.last_name}`.toLowerCase();
        return (
          fullName.includes(searchText) ||
          el.first_name.toLowerCase().includes(searchText) ||
          el.last_name.toLowerCase().includes(searchText) ||
          el.title.toLowerCase().includes(searchText)
        );
      });
    }

    // filter by status
    if (
      state.filters.values.some((val) =>
        state.filters.statusOptions.includes(val)
      )
    ) {
      allEmployees = allEmployees.filter((el2) =>
        state.filters.values.includes(el2.current_status)
      );
    }
    // filter by availability
    const availabilityFilters = ["Availability 0.5 FTE", "Availability 1 FTE"];
    if (state.filters.values.some((val) => availabilityFilters.includes(val))) {
      allEmployees = allEmployees.filter((el2) => {
        return state.filters.values.some((el3) => {
          let val = false;
          if (el3 === "Availability 0.5 FTE") {
            val = el2.availability === 0.5;
          } else if (el3 === "Availability 1 FTE") {
            val = el2.availability === 1;
          }
          return val;
        });
      });
    }

    allEmployees = allEmployees.sort((a, b) => {
      const firstNameA = a?.first_name?.toLowerCase() || "";
      const firstNameB = b?.first_name?.toLowerCase() || "";
      const lastNameA = a?.last_name?.toLowerCase() || "";
      const lastNameB = b?.last_name?.toLowerCase() || "";

      if (firstNameA < firstNameB) return -1;
      if (firstNameA > firstNameB) return 1;

      if (lastNameA < lastNameB) return -1;
      if (lastNameA > lastNameB) return 1;

      return 0;
    });
    return allEmployees;
  });

  const applyFilters = (filters) => {
    const newArray = filters
      .map((item) => {
        if (item.value !== null) {
          if (typeof item === "string") {
            return item;
          } else if (
            typeof item === "object" &&
            // eslint-disable-next-line no-prototype-builtins
            item.hasOwnProperty("value")
          ) {
            return item.value;
          }
        }
      })
      .filter(Boolean);
    filter.setFilters({ key: state.filters.key, filters: newArray });
    state.filters.values = newArray;
    state.filters.showFilterDialog = false;
  };

  const removeFilters = (removedFilters) => {
    filter.setFilters({
      key: state.filters.key,
      filters: state.filters.values,
    });
    state.filters.clearSelection = removedFilters;
  };

  const currentEmployee = computed(
    () =>
      state.assignmentWizard.currentEmployee ||
      state.relieveAssignment.currentEmployee
  );

  const getEmployeeCardProps = (employee) => {
    return {
      firstName: employee.first_name,
      lastName: employee.last_name,
      position: employee.title,
      photoUrl: employee.avatar,
      availability: employee.availability,
      nameUrl: `employees/${employee.id}/pathtoreadiness`,
      showLinkUnderline: false,
      hideAvailabilitySelector: employee.primary_positions?.length > 0 || false,
      isAllocated: employee.primary_positions?.length > 0 || false,
    };
  };

  const getEmployeeAssigmentTagProps = {
    getTypeAssignmentTag: (positionData) => {
      if (positionData.vacant) {
        return "vacant";
      } else if (positionData.occupied) {
        return "occupied";
      } else if (positionData.blocked) {
        return "blocked";
      } else if (positionData.not_allowed) {
        return "not allowed";
      }
    },
    getBasicInfo: (item) => ({
      firstName: item.first_name,
      lastName: item.last_name,
      photoUrl: item.avatar,
      addHoverEffect: true,
    }),

    getPositionInfo: (item) => ({
      positionCode: item.primary_positions[0]?.code.split(" ")[0] || null,
      positionName: item.primary_positions[0]?.name,
      fte:
        item.primary_positions.length > 0
          ? item.primary_positions[0].allocation
          : null,
    }),

    getTypeInfo: (item) => ({
      type:
        item.current_status !== "active"
          ? "not allowed"
          : item.primary_positions?.length > 0
          ? getEmployeeAssigmentTagProps.getTypeAssignmentTag(
              item.primary_positions[0]
            )
          : "no primary assignment",
    }),
  };

  const getStateBadgeProps = (employee) => {
    return {
      access: employee.access ? employee.access : "no-access",
      status: employee.current_status,
    };
  };

  const checkUserEmail = async ({ accountId, email }) => {
    const response = await employees.checkEmailUser({ accountId, email });
    state.giveAccessModal.dataType = response.data;
    if (response.user) {
      state.giveAccessModal.existingUser = response.user;
    }
  };

  const giveAccessToEmployee = async ({ accountId, payload }) => {
    await employees.giveAccessToEmployee({ accountId, payload });
    state.giveAccessModal.show = false;
  };

  const openAssignmentWizard = () => {
    state.assignmentWizard.show = true;
    state.assignmentWizard.selectedPosition = null;
  };

  const closeAssignmentWizard = () => {
    state.assignmentWizard.show = false;
    state.assignmentWizard.selectedPosition = null;
  };

  const openRelieveAssignment = (employee, position) => {
    state.relieveAssignment.currentPosition = position;
    state.relieveAssignment.show = true;
  };

  const closeRelieveAssignment = () => {
    state.relieveAssignment.show = false;
    state.relieveAssignment.currentPosition = null;
    employees.setCurrentEmployee(null);
    state.relieveAssignment.confirmationPending = false;
  };

  const relievePrimaryPositionFromEmployee = async (accountID) => {
    await employees.removePositionAssignmentToEmployee({
      accountId: accountID,
      assignment_id: state.relieveAssignment.currentPosition.id,
    });
    await employees.loadAllEmployees();
    state.relieveAssignment.show = false;
    employees.setCurrentEmployee(null);
  };

  const handleEmployeeDropdownOperation = async (operation, employee) => {
    if (!operation || !employee) return;
    employees.setCurrentEmployee(employee);
    switch (operation.value) {
      case "activate_action":
        state.activeEmployeeModal.message = `${
          employees.getCurrentEmployee()?.first_name || ""
        } ${
          employees.getCurrentEmployee()?.last_name || ""
        } is about to be terminated. We will relieve the employee of all their assignments. We will also suspend access for the employee to this account.`;
        state.activeEmployeeModal.show = true;
        break;
      case "delete_action":
        state.deleteEmployeeModal.message = `${
          employees.getCurrentEmployee()?.first_name || ""
        } ${
          employees.getCurrentEmployee().last_name || ""
        } is about to be Deleted. This action is irreversible.`;
        state.deleteEmployeeModal.show = true;
        break;
      case "edit_action":
        state.addEmployeeModal.show = true;
        break;
      case "change_picture_action":
        state.changePictureModal.show = true;
        break;
      case "give_access_action":
        state.giveAccessModal.show = true;
        break;
      case "suspend_access_action":
        break;
      case "give_back_access_action":
        break;
      case "terminate_action":
        state.terminateEmployeeModal.message = `${
          employees.getCurrentEmployee()?.first_name || ""
        } ${
          employees.getCurrentEmployee()?.last_name || ""
        } is about to be terminated. We will relieve the employee of all their assignments. We will also suspend access for the employee to this account.`;
        state.terminateEmployeeModal.show = true;
        break;
      case "reactivate_action":
        state.reactivateEmployeeModal.show = true;
        break;
      default:
        break;
    }
  };

  const handleEmployeeOperation = async (
    operation,
    accountId,
    payload = null
  ) => {
    try {
      proxy.$loader.show();
      if (operation == employees.operations.TERMINATE_EMPLOYEE) {
        await employees.terminate({
          accountId: accountId,
          employee: employees.getCurrentEmployee(),
        });
        await employees.loadAllEmployees();
        state.terminateEmployeeModal.show = false;
      } else if (operation == employees.operations.ACTIVATE_EMPLOYEE) {
        await employees.activate({
          accountId,
          employee: employees.getCurrentEmployee(),
        });
        await employees.loadAllEmployees();
        state.activeEmployeeModal.show = false;
      } else if (operation == employees.operations.DELETE_EMPLOYEE) {
        await employees.deleteEmp({
          accountId,
          employee: employees.getCurrentEmployee(),
        });
        await employees.loadAllEmployees();
        state.deleteEmployeeModal.show = false;
      } else if (operation == employees.operations.REACTIVATE_EMPLOYEE) {
        await employees.reactivate({
          accountId,
          employee: employees.getCurrentEmployee(),
        });
        await employees.loadAllEmployees();
        state.reactivateEmployeeModal.show = false;
      } else if (operation == employees.operations.EDIT_EMPLOYEE) {
        if (!payload) return;
        const newEmployee = await employees.saveEmployee({
          accountId,
          employee: payload,
        });
        await employees.loadAllEmployees();
        state.addEmployeeModal.show = false;
        if (!payload?.id) {
          if (newEmployee) {
            const { data } = newEmployee;
            state.filters.searchText = `${data.first_name} ${data.last_name}`;
          }
        }
      } else if (operation == employees.operations.CHANGE_EMPLOYEE_PICTURE) {
        const newEmployee = {
          id: employees.getCurrentEmployee()?.id,
          first_name: employees.getCurrentEmployee()?.first_name,
          last_name: employees.getCurrentEmployee()?.last_name,
          title: employees.getCurrentEmployee()?.title,
          avatar: payload,
        };
        await employees.saveEmployeeWithAvatar({
          accountId,
          employee: newEmployee,
        });
        state.changePictureModal.show = false;
      }
      employees.setCurrentEmployee(null);
    } catch (e) {
      console.error("error on employee operation: ", e);
    } finally {
      proxy.$loader.hide();
    }
  };

  const selectEmployee = (employee) => {
    const index = state.table.selectedEmployees.findIndex(
      (e) => e.id === employee.id
    );
    if (index === -1) {
      state.table.selectedEmployees.push(employee);
    } else {
      state.table.selectedEmployees.splice(index, 1);
    }
  };

  // Métodos para filtros
  const updateFilter = (filterName, value) => {
    if (filterName in state.filters) {
      state.filters[filterName] = value;
    }
  };

  const clearFilters = () => {
    state.filters = {
      status: "all",
      department: null,
      position: null,
    };
  };

  // Métodos para loading
  const setLoading = (key, value) => {
    if (key in state.loading) {
      state.loading[key] = value;
    }
  };

  instance = {
    state,
    hasSelectedEmployees,
    currentEmployee,
    openAssignmentWizard,
    closeAssignmentWizard,
    openRelieveAssignment,
    closeRelieveAssignment,
    selectEmployee,
    updateFilter,
    clearFilters,
    setLoading,
    getEmployeeCardProps,
    getEmployeeAssigmentTagProps,
    relievePrimaryPositionFromEmployee,
    getStateBadgeProps,
    handleEmployeeDropdownOperation,
    handleEmployeeOperation,
    checkUserEmail,
    filteredEmployees,
    applyFilters,
    removeFilters,
    giveAccessToEmployee,
  };
  return instance;
}
