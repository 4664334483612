var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"select-picker"},[_c('div',{staticClass:"text-medium-emphasis label-name"},[_vm._v(_vm._s(_vm.pickerLabel)+"*")]),_c('v-text-field',{ref:"pickerFieldRef",staticClass:"custom-response-picker",class:[
      _vm.selectedItemFullName ? 'auto-picker' : 'custom-response-picker',
      _vm.showClearIcon ? 'sh-clear-icon' : 'sh-not-clear-icon',
      (_vm.selectedItemFullName && _vm.isReadonly === false  ) ? 'picker-searching' : '',
    ],attrs:{"outlined":"","placeholder":_vm.placeholder,"hide-details":true,"append-icon":_vm.appendIcon,"readonly":_vm.isReadonly,"clearable":_vm.isSelectedItemRemove ? (_vm.selectedItemObject === null ? false : true) : false},on:{"input":_vm.searchItems,"focus":_vm.onInputFocus,"blur":_vm.onInputBlur,"keydown":_vm.onKeyDown,"click:append":_vm.onAppendIconClick,"click:clear":_vm.resetSelectedItem},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.isInputFocused && !_vm.selectedItemFullName)?_c('img',{staticClass:"prepend-icon-inner-custom-search",attrs:{"src":require('@components/assets/search-lg.png'),"alt":"icon"}}):_vm._e(),(_vm.selectedItemObject && _vm.hasIcon(_vm.selectedItemObject?.icon))?_c('Avatar',{staticClass:"selected-currency-icon prepend-icon-inner-custom",attrs:{"picture":_vm.hasIcon(_vm.selectedItemObject.icon),"size":"small"}}):_vm._e()]},proxy:true},{key:"label",fn:function(){return [(_vm.isInputFocused)?_c('label',{staticClass:"response-label-class",class:{
          'custom-label-class': !_vm.isInputFocused && _vm.selectedItemFullName,
        }},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e(),(!_vm.isInputFocused && !_vm.selectedItemFullName)?_c('label',{staticClass:"response-label-class"},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.selectedItemFullName),callback:function ($$v) {_vm.selectedItemFullName=$$v},expression:"selectedItemFullName"}}),(_vm.isListVisible && _vm.filteredData.length)?_c('div',{staticClass:"list-picker-container"},[(_vm.isListVisible && _vm.filteredData.length)?_c('v-list',{staticClass:"res-custom-list-picker",class:{
      'res-custom-list-picker-change': _vm.isInputFocused,
    }},[_vm._l((_vm.filteredData),function(item,index){return _c('v-list-item',{key:`item-${index}-${item.id}`,staticClass:"res-v-list-item",class:{
        'highlighted-item': _vm.isSelected(item),
      },on:{"click":function($event){return _vm.onListItemClick(item)}}},[(_vm.hasIcon(item.icon))?_c('Avatar',{staticClass:"list-currency-icon",attrs:{"size":"small","picture":_vm.hasIcon(item.icon)}}):_vm._e(),_c('v-list-item-content',{staticClass:"selector-list-items"},[_c('v-list-item-title',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),(_vm.useCode && item.hasOwnProperty('code'))?_c('div',{staticClass:"item-code"},[_vm._v("   ("+_vm._s(item.code)+") ")]):_vm._e()]),(_vm.isSelected(item))?_c('img',{staticClass:"currency-right-icon",attrs:{"src":require("@components/assets/rightIcon.svg"),"alt":"icon"}}):_vm._e()])],1)],1)}),(!_vm.filteredData.length)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"no-text"},[_vm._v("Nothing matching your search")])],1)],1):_vm._e()],2):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }