<template>
  <v-card
      flat
      class="ex-universal-table-container"
      :class="{ 'background-pattern': items.length == 0 }"
  >
    <NoRecordFound
        v-if="items.length === 0 && config.filters.searchText.length > 0"
        title="Employees"
        message="Employees"
        :btnTitle="'Employee'"
        @onAction="$emit('onActionClick')"
        class="no-record-found"
        :clearLabel="clearState.label"
        :showClearAction="true"
        :description="clearState.description"
        :noBorder="true"
        @onClear="$emit('onSearch', '')"
    />
    <div class="d-flex flex-column" v-else>
      <div v-if="!config?.hideTopSection" class="d-flex pa-6 justify-space-between align-center">
        <div class="d-flex align-center">
          <span class="text-title mr-2 align-self-end">{{ config?.title || '' }}</span>
          <div class="active-badge-parent">
            <span v-if="!config?.hideActiveNo" class="active-badge">
              {{ items && items.length > 0 && config?.activeRecords?.field && config?.activeRecords?.value ? items.filter(el => el[config?.activeRecords?.field] == config?.activeRecords?.value).length : 0 }} active
            </span>
          </div>
        </div>
        <div v-if="items.length > 0">
          <Button
              :icon="'add-icon.svg'"
              @onClick="$emit('onActionClick')"
              :normal="'normal-active'"
              :label="config?.actionLabel || 'Add Record'"
          ></Button>
        </div>
      </div>
      <div class="content">
        <div class="px-5 mb-5">
          <SearchBar
              type="button"
              :label="config.filters.values.length > 0 ? 'More Filters' : 'Filters'"
              :length="config.filters.values.length"
              :chipText="config.filters.values"
              ghostText="Search"
              :add="false"
              :valueSearch="config.filters.searchText"
              @onSearch="emit('onSearch', $event)"
              @onClick="emit('onClick')"
              @removeFilter="emit('removeFilter', $event)"
          ></SearchBar>
        </div>
        <div class="d-flex flex-column">
          <v-data-table
              :headers='config.headers'
              :items="paginatedItems"
              :items-per-page="-1"
              hide-default-footer
              hide-default-header
              class="ex-universal-table"
              :loading="loading"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
              <tr>
                <th
                    v-for="(item, index) in headers"
                    :key="`header-${index}`"
                    :class="{
                      'highlight-table-header': item.sortable,
                      'curren-badge-header': item.centerField
                    }"
                >
                  <template v-if="item.img">
                    <div class="d-flex align-center">
                      <img class="mr-2" :src="require(`@components/assets/${item.img}`)" />
                      <span class="header-text text-xs">{{ item.text }}</span>
                    </div>
                  </template>
                  <template v-else-if="item.multiple">
                    <div class="d-flex">
                      <div
                          class="d-flex mr-3 align-center"
                          v-for="(dataItem, index) in item.data"
                          :key="`${dataItem.text}-${index}`"
                      >
                        <img v-if="dataItem.img" class="mr-2" :src="require(`@components/assets/${dataItem.img}`)" />
                        <span class="header-text text-xs">{{ dataItem.text }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <span class="header-text text-xs">{{ item.text }}</span>
                  </template>
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="items && items.length > 0">
              <tr
                  v-for="(item, rowIndex) in items"
                  :key="`row-${item.id || rowIndex}-${getItemVersion(item)}`"
                  :style="getRowStyle(item)"
              >
                <td
                    v-for="(column, colIndex) in config.columns"
                    :key="`col-${colIndex}`"
                >
                  <template v-if="column.component">
                    <keep-alive :max="50">
                      <component
                          v-if="loadedComponents[column.component]"
                          :is="loadedComponents[column.component]"
                          :key="`${item.id}-${getItemVersion(item)}`"
                          v-bind="getComponentProps(column, item)"
                          v-on="getComponentEvents(column, item)"
                      >
                        <template
                            v-for="slotName in getAvailableSlots(column.component)"
                            :slot="slotName"
                        >
                          <slot
                              :name="`${column.component}-${slotName}`"
                              v-bind="getSlotProps(column, item, slotName)"
                          >
                            <template v-if="column.slots && column.slots[slotName]">
                              <component
                                  :is="column.slots[slotName]"
                                  v-bind="getSlotProps(column, item, slotName)"
                              />
                            </template>
                          </slot>
                        </template>
                      </component>
                    </keep-alive>
                  </template>
                  <template v-else>
                    <span></span>
                  </template>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
          <div v-if="showPagination" class="d-flex justify-space-between align-center px-6 py-4">
            <Button
                :icon="'arrow-icon.svg'"
                @onClick="onPreviousPage"
                size="small"
                :outline="'outline-active'"
                label="Previous"
                :class="{'prev-hide': !showPreviousButton}"
            />
            <v-pagination
                class="data-table-pagination"
                v-model="currentPage"
                :length="totalPages"
                :total-visible="7"
                @input="onChangePage"
            />
            <Button
                :rightIcon="'right-arrow-icon.svg'"
                @onClick="onNextPage"
                size="small"
                :outline="'outline-active'"
                label="Next"
                :class="{'next-hide': !showNextButton}"
            />
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, onMounted, watch, defineProps, defineEmits, nextTick } from 'vue'
import Button from "@components/buttons/Button.vue";
import SearchBar from "@components/SearchBar/SearchBar.vue";
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";

const ITEMS_PER_PAGE = 10;
const COMPONENT_SLOTS = {
  'Dropdown': ['activator'],
}

const COMPONENT_MAP = {
  'EmployeeCard': () => import('@components/organizations/employees/EmployeeCard.vue'),
  'EmployeeAssigmentTag': () => import('@components/organizations/positions/EmployeeAssigmentTag.vue'),
  'EmployeeBadge': () => import('@components/organizations/employeeStatusBadge/EmployeeBadge.vue'),
  'Dropdown': () => import('@components/DropDownMenu/DropDownMenu.vue')
}

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
  items: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  useStatusRowColoring: {
    type: Boolean,
    default: false,
  },
  noHoverEffect: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['onActionClick', 'onSearch', 'onClick', 'removeFilter', 'clearSearch'])

const currentPage = ref(1)
const loadedComponents = ref({})
const loadingComponents = ref(new Set())
const itemsVersionMap = ref(new Map())
const isInitialized = ref(false);

// Computed properties for pagination
const showPagination = computed(() => {
  return !props.config.filters.searchText && props.items.length > ITEMS_PER_PAGE
})

const totalPages = computed(() => {
  if (props.config.filters.searchText) return 1
  return Math.ceil(props.items.length / ITEMS_PER_PAGE)
})

const showPreviousButton = computed(() => {
  return showPagination.value && currentPage.value > 1
})

const showNextButton = computed(() => {
  return showPagination.value && currentPage.value < totalPages.value
})

const paginatedItems = computed(() => {
  if (!isInitialized.value) return []

  if (props.config.filters.searchText) return props.items

  const start = (currentPage.value - 1) * ITEMS_PER_PAGE
  const end = start + ITEMS_PER_PAGE
  return props.items.slice(start, end)
})

// Component loading functions
const getAvailableSlots = (componentName) => {
  return COMPONENT_SLOTS[componentName] || []
}

const getSlotProps = (column, item, slotName) => {
  if (typeof column.slotProps === 'function') {
    return column.slotProps(item, slotName)
  }
  return column.slotProps?.[slotName] || {}
}

const getItemVersion = (item) => {
  const relevantData = JSON.stringify({
    ...item,
    _timestamp: Date.now()
  })
  return relevantData
}

const loadComponent = async (componentName) => {
  if (loadedComponents.value[componentName] || loadingComponents.value.has(componentName)) {
    return
  }

  loadingComponents.value.add(componentName)
  try {
    const componentLoader = COMPONENT_MAP[componentName]
    if (componentLoader) {
      const component = await componentLoader()
      loadedComponents.value[componentName] = component.default || component
    } else {
      console.warn(`Component ${componentName} not found in COMPONENT_MAP`)
    }
  } catch (error) {
    console.error(`Error loading component ${componentName}:`, error)
  } finally {
    loadingComponents.value.delete(componentName)
  }
}

const loadAllRequiredComponents = async () => {
  if (!props.config?.columns || props.config.columns.length === 0) {
    return
  }

  const componentNames = props.config.columns
      .filter(col => col.component)
      .map(col => col.component)

  await Promise.all(
      componentNames.map(name => loadComponent(name))
  )
}

const getRowStyle = (item) => {
  if (props.config.getRowStyle) {
    return props.config.getRowStyle(item)
  }
  return {}
}

const getComponentProps = (column, item) => {
  if (typeof column.props === 'function') {
    return column.props(item)
  }
  return column.props || {}
}

const getComponentEvents = (column, item) => {
  if (typeof column.events === 'function') {
    return column.events(item)
  }
  return column.events || {}
}

// Pagination handlers
const onNextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++
  }
}

const onPreviousPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--
  }
}

const onChangePage = (page) => {
  currentPage.value = page
}

watch(() => props.config.columns, () => {
  loadAllRequiredComponents()
}, { immediate: true, deep: true })

watch(() => props.config.filters.searchText, () => {
  currentPage.value = 1
})

watch(() => props.items, () => {
  if (currentPage.value > totalPages.value && totalPages.value > 0) {
    currentPage.value = totalPages.value
  }
}, { deep: true })

onBeforeUnmount(() => {
  itemsVersionMap.value.clear()
})

onMounted(async () => {
  await loadAllRequiredComponents()
  nextTick(() => {
    isInitialized.value = true
  });
})

const clearState = computed(() => {
  if (props.config.filters.searchText.length > 0 && props.config.filters.values?.length > 0) {
    return {
      label: "Clear All",
      description: `Clear All Filters & Search to view Employees`,
    };
  } else if (props.config.filters.searchText.length > 0) {
    return {
      label: "Clear Search",
      description: `Clear Search to view Employees`,
    };
  } else if (props.config.filters.values?.length > 0) {
    return {
      label: "Clear Filter",
      description: `Clear Filters to view Employees`,
    };
  } else {
    return {
      label: "Clear",
      description: `Clear to view Employees`,
    };
  }
})

</script>

<style scoped>
.ex-universal-table-container {
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  overflow: hidden;
}
.ex-universal-table thead {
  background-color: #f9fafb;
  border-radius: 20px;
}
.data-table .highlight-table-header {
  cursor: pointer;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-top: 1px solid #eaecf0 !important;
  border-bottom: 1px solid #eaecf0 !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th:first-child {
  border-left: none !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th:last-child {
  border-right: none !important;
}
.header-text {
  font-weight: 500;
  line-height: 18px;
  color: #667085;
  white-space: nowrap;
}
.data-table-pagination .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  box-shadow: none;
  color: #667085 !important;
  font-size: 14px;
}
.data-table-pagination .theme--light.v-pagination .v-pagination__item--active {
  background: #f9f5ff !important;
  color: #7f56d9 !important;
}
.data-table-pagination .theme--light.v-pagination .v-pagination__navigation {
  display: none !important;
}
.ex-universal-table .v-data-table__wrapper {
  border-bottom: 1px solid #eaecf0;
}
.user-tr .data-table {
  line-height: 5;
}
.prev-hide {
  visibility: hidden;
}
.next-hide {
  visibility: hidden;
}
.ex-universal-table-container .text-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.ex-universal-table-container .active-badge {
  padding: 2px 8px;
  background-color: #F9F5FF;
  color: #6941C6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
}
</style>
