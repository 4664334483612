import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/Login.vue";
import AdminView from "@/views/AdminView.vue";
import PositionView from "@/views/PositionView.vue";
import SettingView from "@/views/SettingView.vue";
import ReadinessCatalogsView from "@/views/ReadinessCatalogsView.vue";
import ReadinessEmployeeView from "@/views/ReadinessEmployeeView.vue";
import ResBadgeHolderView from "@/views/ResBadgeHolderView.vue";
import OrginationPositionView from "@/views/OrginationPositionView.vue";
import EmployeePathToReadinessView from "@/views/DetailEmployeeViews/EmployeePathToReadinessView.vue";
import DetailEmployeeView from "@/views/DetailEmployeeView.vue";
import EmployeeDetailDashboardView from "@/views/DetailEmployeeViews/EmployeeDetailDashboardView.vue";
import RolePageView from "@/views/RolePageView.vue";
import BeMasterCoachView from "@/views/BeMasterCoachView.vue";
import EmployeeChatterView from "@/views/DetailEmployeeViews/EmployeeChatterView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import NotificationsListView from "@components/views/NotificationsListView.vue";
import NotFoundView from "@/views/NotFound.vue";
import NewEmployeeView from "@/views/Employees/EmployeeView.vue";
import EmployeeBadges from "@components/EmployeeBadges/EmployeeBadges.vue";
import EmployeeAssignmentView from "@/views/DetailEmployeeViews/EmployeeAssignmentView.vue";
import store from "../store";
import AsEmployee from "@/views/AsEmployee.vue";

Vue.use(VueRouter);

const checkMyStuffMiddleware = (to, from, next) => {
  const currentAccount = JSON.parse(localStorage.getItem("accessPermission"));
  if (
    Object.prototype.hasOwnProperty.call(currentAccount, "has_employee") &&
    currentAccount.has_employee === true
  ) {
    next();
  } else {
    next({ name: "Employees" });
  }
};

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { hideMainSidebar: true },
  },
  {
    path: "/organization/employees",
    name: "Employees",
    component: NewEmployeeView,
  },
  {
    path: "/organization/employees/:id(\\d+)",
    name: "DetailEmployee",
    component: DetailEmployeeView,
    meta: { secondSidebar: "employee" },
    children: [
      {
        path: "/organization/employees/:id(\\d+)/dashboard",
        name: "DetailEmployeeDashboard",
        component: EmployeeDetailDashboardView,
        meta: { secondSidebar: "employee" },
      },
      {
        path: "/organization/employees/:id(\\d+)/pathtoreadiness",
        name: "EmployeePathToReadiness",
        component: EmployeePathToReadinessView,
        meta: { secondSidebar: "employee" },
      },
      {
        path: "/organization/employees/:id(\\d+)/chatter",
        name: "EmployeePathToReadiness",
        component: EmployeeChatterView,
        meta: { secondSidebar: "employee" },
      },
      {
        path: "/organization/employees/:id/badges",
        name: "EmployeePathToReadiness",
        component: EmployeeBadges,
        meta: { secondSidebar: "employee" },
      },
      {
        path: "/organization/employees/:id(\\d+)/assignments",
        name: "EmployeeAssignments",
        component: EmployeeAssignmentView,
        meta: { secondSidebar: "employee" },
      },
    ],
  },
  {
    path: "/organization/positions",
    name: "Positions",
    component: PositionView,
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminView,
    meta: { sideBarPresent: false, role: "admin" },
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingView,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    meta: { hideMainSidebar: true },
  },
  {
    path: "/setup/password",
    name: "SetNewPassword",
    component: ForgotPasswordView,
    meta: { hideMainSidebar: true },
  },
  {
    path: "/reset/password",
    name: "SetNewPasswordReset",
    component: ForgotPasswordView,
    meta: { hideMainSidebar: true },
  },
  {
    path: "/my-stuff/employee",
    name: "AsEmployee",
    component: AsEmployee,
    beforeEnter: checkMyStuffMiddleware,
  },
  {
    path: "/readiness/catalogs",
    name: "ReadinessCatalogs",
    component: ReadinessCatalogsView,
  },
  {
    path: "/readiness/catalogs/:type(\\w+)",
    name: "ReadinessCatalogsDetail",
    component: ReadinessCatalogsView,
  },
  {
    path: "/readiness/catalogs/responsibilities/:objId(\\d+)",
    name: "ReadinessCatalogsDetailObjId",
    component: ResBadgeHolderView,
  },
  {
    path: "/readiness/catalogs/roles/:objId(\\d+)",
    name: "ReadinessCatalogsDetailObjId",
    component: RolePageView,
  },
  {
    path: "/readiness/employees",
    name: "ReadinessEmployees",
    component: ReadinessEmployeeView,
  },
  {
    path: "/readiness/responsibility/holder/:resId(\\d+)",
    name: "ResBadgeHolder",
    component: ResBadgeHolderView,
  },
  {
    path: "/organization/positions/position/:pId(\\d+)",
    name: "OrgPosition",
    component: OrginationPositionView,
  },
  {
    path: "/readiness/catalogs/roles/:type(\\w+)/:objId(\\d+)",
    name: "ReadinessCatalogsRolesResponse",
    component: ResBadgeHolderView,
  },
  {
    path: "/organization/positions/:pId(\\d+)/:type(\\w+)/:objId(\\d+)",
    name: "OrgPositionDetailObjId",
    component: RolePageView,
  },
  {
    path: "/organization/positions/:pId(\\d+)/roles/:type(\\w+)/:objId(\\d+)",
    name: "OrgPositionRolesResponse",
    component: ResBadgeHolderView,
  },
  {
    path: "/services/be-master-coach",
    name: "BeMasterCoach",
    component: BeMasterCoachView,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationsListView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgot-password",
    "/setup/password",
    "/reset/password",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn =
    localStorage.getItem("token") && localStorage.getItem("currentAccountID");
  store.dispatch("routeErrorHandling/clearError");
  if (authRequired && !loggedIn) {
    if (to.fullPath == "/") {
      return next("/login");
    } else {
      localStorage.clear();
      return next({ path: "/login", query: { nextUrl: to.fullPath } });
    }
  }
  if (!authRequired) {
    localStorage.clear();
  }
  next();
});

export default router;
